<template>
  <b-container fluid id="main">
    <div class="buttons">
      <add-keycloak-button :client="client" :callback="selectKeycloakTab"/>
      <generate-api-keys-button :client="client" :callback="selectAndRefreshApiKeysTab"/>
    </div>
    <h1><inline-input :text="client.name" :save="renameClient"/></h1>

    <b-tabs card>
      <b-tab no-body
             :active="localStore.selectedTab === null"
             @click="selectTab(null)"
      >
        <template #title>
          <b-icon-star-fill class="star-icon" variant="warning" v-if="client.starredTab === -1"/>API keys{{ client.apiKeyCount > 0 ? ' (' + client.apiKeyOnlineCount + '/' + client.apiKeyAssignedCount + '/' + client.apiKeyCount + ')': '' }}
        </template>
        <client-api-keys :client="client" ref="apiKeys"/>
      </b-tab>
      <b-tab no-body v-for="keycloak in client.keycloaks" :key="keycloak.id"
             :active="localStore.selectedTab !== null && localStore.selectedTab.id === keycloak.id"
             @click="selectTab(keycloak)"
      >
        <template #title>
          <b-icon-star-fill class="star-icon" variant="warning" v-if="client.starredTab === keycloak.id"/>{{ keycloak.name }}{{ ' (' + keycloak.apiKeyOnlineCount + '/' + keycloak.apiKeyCount + ')' }}
        </template>
        <keycloak-details v-if="localStore.selectedTab !== null && localStore.selectedTab.id === keycloak.id" :client="client" :keycloak="keycloak"/>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import {store} from "@/main"
import GenerateApiKeysButton from "@/components/buttons/GenerateApiKeysButton.vue"
import ClientApiKeys from "@/components/ClientApiKeys"
import KeycloakDetails from "@/components/KeycloakDetails.vue";
import AddKeycloakButton from "@/components/buttons/AddKeycloakButton.vue";
import InlineInput from "@/components/atoms/InlineInput.vue";
import {API} from "aws-amplify";

const localStore = {
  selectedTab: null,
  currentClientId: null,
  nextTabToSelect: null,
}

const selectTab = function (tab) {
  if (localStore.selectedTab !== tab) {
    localStore.selectedTab = tab
  }
}

const selectDefaultTab = function (client) {
  if (client.id === localStore.currentClientId) {
    if (localStore.nextTabToSelect !== null) {
      selectKeycloakTab(client, localStore.nextTabToSelect)
    }
  } else {
    localStore.currentClientId = client.id
    if (client.starredTab !== undefined && client.starredTab !== null) {
      if (client.starredTab !== -1) {
        for (let keycloak of Object.values(client.keycloaks)) {
          if (keycloak.id === client.starredTab) {
            selectTab(keycloak)
            return
          }
        }
      }
      localStore.selectedTab = null
    } else if (client.apiKeyCount === 0 && Object.keys(client.keycloaks).length !== 0) {
      localStore.selectedTab = Object.values(client.keycloaks)[0]
    } else {
      localStore.selectedTab = null
    }
  }
}

const selectKeycloakTab = function(client, keycloakId) {
  for (let keycloak of Object.values(client.keycloaks)) {
    if (keycloak.id === keycloakId) {
      selectTab(keycloak)
      localStore.nextTabToSelect = null
      return
    }
  }
  // if no matching keycloak was found, set it for next tab
  localStore.nextTabToSelect = keycloakId
}

export default {
  name: "ClientDetails",
  props: {
    clientId: String
  },
  components: {InlineInput, KeycloakDetails, GenerateApiKeysButton, ClientApiKeys, AddKeycloakButton},
  data() {
    return {
      localStore: localStore,
      storeState: store.state,
    }
  },
  methods: {
    selectAndRefreshApiKeysTab() {
      selectTab(null)
      this.$refs.apiKeys.refreshKeys()
    },
    selectTab(tab) {selectTab(tab)},
    selectKeycloakTab(keycloakId) {
      selectKeycloakTab(this.client, keycloakId)
    },
    renameClient(name, callback) {
      API.post(store.state.api, "/put", {
        body: JSON.stringify({
          q: 53,
          name: name,
          clientId: this.client.id,
        }),
      })
          .then((response) => {
            if (response === true) {
              this.storeState.clients[this.client.id].name = name
              callback()
            } else {
              throw response
            }
          })
          .catch((e) => {
            console.log(e)
            callback()
          })
    },
  },
  computed: {
    client() {
      if (this.storeState.clients === null) {
        return {}
      }
      return this.storeState.clients[parseInt(this.$props.clientId)]
    }
  },
  watch: {
    client() {
      this.$nextTick(() => {
        selectDefaultTab(this.client)
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      selectDefaultTab(this.client)
    })
  }
}
</script>

<style scoped>
#main {
  padding-top: 15px;
}
.star-icon {
  height: 16px;
  width: 16px;
  margin-bottom: 2px;
  margin-right: 5px;
}
.buttons {
  float: right;
  vertical-align: top;
}
.buttons > * {
  margin-left: 10px;
}

</style>
