<template>
  <b-container fluid id="main">
    <div class="buttons">
      <delete-keycloak-button :keycloak="keycloak" />
      <download-keycloak-api-key-usage-csv-button :client="client" :keycloak="keycloak" :api-keys="localStore.apiKeys === null ? [] : localStore.apiKeys" :disabled="localStore.apiKeys === null"/>
    </div>
    <h1><inline-input :text="keycloak.name" :save="renameKeycloak"/> <star-tab :client="client" :tab-id="keycloak.id" /><span v-if="keycloak.keycloakApiKey !== null" id="keycloakapikey">({{ keycloak.keycloakApiKey }}) <b-icon-clipboard-check variant="primary" @click="copyApiKey"/></span></h1>
    <b-row>
      <b-col lg="3">
        <p style="margin-bottom: 0" v-if="storeState.servers !== null">{{ storeState.servers[keycloak.serverId] ? storeState.servers[keycloak.serverId].url : '' }} </p>
        <p>Versions: {{ keycloak.keycloakVersion !== undefined && keycloak.pluginVersion !== undefined ? keycloak.keycloakVersion + ' - ' + keycloak.pluginVersion : '-' }}</p>
      </b-col>
      <b-col lg="7">
        <b-button :variant="(editingPermissions || savingPermissions) ? 'success' : 'outline-success'" id="editButton" @click="permissionsButtonClicked">
          <span v-if="editingPermissions"><b-icon-pencil-fill style="margin-right: 5px"/> Save</span>
          <b-spinner small v-else-if="savingPermissions"></b-spinner>
          <span v-else><b-icon-pencil style="margin-right: 5px"/> Edit</span>
        </b-button>


        <p style="margin-bottom: 0">Permissions:</p>
        <b-form-checkbox-group
            id="keycloak-permission-checkboxes-group"
            v-model="selectedPermissions"
            :options="permissionOptions"
            name="keycloak-permission-checkboxes"
            :disabled="!editingPermissions"
            inline
        />
      </b-col>
      <b-col lg="2">
        <b-form-select v-model="localStore.days" :options="daysOptions" @change="refreshGraph"></b-form-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <line-chart v-if="localStore.chartData !== null" :chart-data="localStore.chartData" :options="localStore.chartOptions" :styles="chartStyles" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {API} from "aws-amplify";
import {store, formatShortDate, refreshClients} from "@/main";
import LineChart from "./keycloak_graphs/LineChart.js";
import DownloadKeycloakApiKeyUsageCsvButton from "./buttons/DownloadKeycloakApiKeyUsageCsvButton.vue";
import InlineInput from "@/components/atoms/InlineInput.vue";
import DeleteKeycloakButton from "@/components/buttons/DeleteKeycloakButton.vue";
import StarTab from "@/components/atoms/StarTab.vue";


const localStore = {
  days: 0,
  apiKeys: null,
  firstApiKeyTimestamp: null,
  chartData: null,
  chartOptions: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'API key count',
        },
        ticks: {
          min: 0,
        },
      }]
    }
  }
}


const refreshApiKeys = function (keycloakId) {
  localStore.apiKeys = null
  localStore.firstApiKeyTimestamp = null

  API.post(store.state.api, "/get", {
    body: JSON.stringify({
      q: 7,
      keycloakId: keycloakId,
    }),
  })
      .then(response => {
        localStore.apiKeys = response
        if (response.length > 0) {
          localStore.firstApiKeyTimestamp = Math.min(...(response.map(apiKey => apiKey.creationTimestamp)))
        } else {
          localStore.firstApiKeyTimestamp = null
        }
        recomputeGraph()
      })
      .catch((e) => {
        console.log(e)
        localStore.apiKeys = []
      });
}

const recomputeGraph = function() {
  if (localStore.apiKeys === null) {
    localStore.chartData = null
    return
  }

  const step = 86400000
  const endTime = (() => {
    const date = new Date();
    date.setHours(0, 0, 0)
    return date.getTime()
  })();
  const points = (localStore.firstApiKeyTimestamp === null) ? 10 : Math.floor(1 + (endTime - localStore.firstApiKeyTimestamp) / step)
  const startTime = endTime - (points-1) * step
  const labels = new Array(points + 1)
  for (let i = 0; i < points; i ++) {
    labels[i] = formatShortDate(startTime + i*step)
  }
  labels[points] = "now"

  const changes = new Array(points + 1).fill(0)
  const active = new Array(points + 1).fill(0)

  for (let key of localStore.apiKeys) {
    let startIndex = Math.max(0, Math.floor(1 + (key.creationTimestamp - startTime - 1) / step))
    changes[startIndex]++
    let endIndex = points
    if (key.revocationTimestamp) {
      endIndex = Math.min(points, Math.floor(1 + (key.revocationTimestamp - startTime - 1) / step))
      changes[endIndex]--
    }

    if (key.lastRegTimestamp && !key.revocationTimestamp) {
      let regIndex = Math.floor(1 + (key.lastRegTimestamp - startTime - 1) / step)
      active[regIndex]++
    }
  }

  const data = new Array(points + 1).fill(0)
  data[0] = changes[0]
  for (let i = 1; i <= points; i++) {
    data[i] = changes[i] + data[i-1]
  }

  localStore.chartData = {
    labels: (localStore.days !== 0) ? labels.slice(-localStore.days) : labels,
    datasets: [
      {
        label: 'API key count',
        borderColor: '#f82050',
        backgroundColor: '#ff708050',
        data: (localStore.days !== 0) ? data.slice(-localStore.days) : data,
      },
      {
        type: 'bar',
        barPercentage: 1,
        categoryPercentage: 1,
        label: 'Active users',
        backgroundColor: '#0066cc',
        data: (localStore.days !== 0) ? active.slice(-localStore.days) : active,
      },
    ]
  }
}

export default {
  name: "KeycloakDetails",
  props: {
    client: Object,
    keycloak: Object,
  },
  components: {StarTab, DeleteKeycloakButton, InlineInput, DownloadKeycloakApiKeyUsageCsvButton, LineChart},
  data() {
    return {
      localStore: localStore,
      storeState: store.state,
      editingPermissions: false,
      selectedPermissions: [],
      permissionOptions: [{ text: 'Call', value: 'call', }, { text: 'Multi-device', value: 'multi_device', }],
      savingPermissions: false,
      daysOptions: [
        { value: 0, text: 'Since origin'},
        { value: 15, text: '15 days'},
        { value: 30, text: '30 days'},
        { value: 90, text: '90 days'},
      ],
    }
  },
  methods: {
    refreshGraph() {
      localStorage.setItem("days", localStore.days)
      recomputeGraph()
    },
    setCurrentKeycloakPermissions() {
      this.selectedPermissions = this.keycloak.permissions === undefined ? [] : [...this.keycloak.permissions]
      this.editingPermissions = false
      this.savingPermissions = false
    },
    permissionsButtonClicked() {
      if (this.savingPermissions) {
        return
      }
      if (this.editingPermissions) {
        this.editingPermissions = false
        if (this.client.permissions !== this.selectedPermissions) {
          this.savingPermissions = true
          // permissions were changed --> send the query to update keycloak permissions
          API.post(store.state.api, "/put", {
            body: JSON.stringify({
              q: 52,
              keycloakId: this.keycloak.id,
              permissions: this.selectedPermissions,
            }),
          })
              .then((response) => {
                if (response === true) {
                  refreshClients()
                  this.savingPermissions = false
                } else {
                  throw response
                }
              })
              .catch((e) => {
                console.log(e)
                this.setCurrentKeycloakPermissions()
              })
        }
      } else {
        this.editingPermissions = true
      }
    },
    renameKeycloak(name, callback) {
      API.post(store.state.api, "/put", {
        body: JSON.stringify({
          q: 54,
          name: name,
          keycloakId: this.keycloak.id,
        }),
      })
          .then((response) => {
            if (response === true) {
              this.storeState.clients[this.client.id].keycloaks[this.keycloak.id].name = name
              callback()
            } else {
              throw response
            }
          })
          .catch((e) => {
            console.log(e)
            callback()
          })
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.keycloak.keycloakApiKey)
      this.$bvToast.toast('Api key copied to clipboard', {
        variant: "success",
        toaster: "b-toaster-top-center",
        autoHideDelay: 1000,
        noCloseButton: true,
      })
    },
  },
  computed: {
    chartStyles() {
      return {
        position: "relative",
        height: "50vh",
      }
    }
  },
  watch: {
    keycloak() {
      this.setCurrentKeycloakPermissions()
      refreshApiKeys(this.keycloak.id)
    },
  },
  mounted() {
    localStore.days = localStorage.getItem("days") || 0
    this.setCurrentKeycloakPermissions()
    refreshApiKeys(this.keycloak.id)
  }
}
</script>

<style scoped>
#main {
  padding-top: 15px;
}
.buttons {
  float: right;
}
.buttons > * {
  margin-left: 10px;
}
#keycloakapikey {
  font-size: 40%;
  font-weight: lighter;
  margin-left: 20px;
  font-family: monospace;
}
#editButton {
  float: left;
  width:100px;
  margin-right: 10px;
  margin-top: 6px;
}
</style>